/* eslint-disable arrow-parens */
<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card ref="loadingCard">
          <div class="custom-search d-flex justify-content-end">
            <b-col md="6">
              <b-button
                v-if="idDoPerfil === 1 || idDoPerfil === 2 || idDoPerfil === 3"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="AjusteButtonAdd"
                @click="addImport()"
              >
                <img
                  width="15px"
                  :src="require('/src/assets/images/icons/Microsoft-xls.png')"
                >
                Importar
              </b-button>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <b-form-input
                    v-model="searchQuery"
                    placeholder="Procurar..."
                    type="text"
                    class="d-inline-block"
                  />
                  <!-- <label class="ml-1 mr-1">Pesquisar</label> -->
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="ml-1"
                    variant="primary"
                    @click="addClient()"
                  >
                    Adicionar
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
          </div>

          <!-- table -->
          <b-card-actions
            ref="loadingCard"
            no-actions
          >
            <vue-good-table
              :columns="columns"
              :rows="getUsers"
              :search-options="{
                enabled: true,
                externalQuery: searchQuery
              }"
              :select-options="{
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength
              }"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Name -->
                <span v-if="props.column.field === 'nomeDoCliente'">
                  <span>{{ props.row.nomeDoCliente }}</span>
                </span>
                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <span>
                    <a
                      v-b-tooltip.hover.bottom="'Detalhes'"
                      :href="
                        '/consultar-usuario/' +
                          props.row.idDoCliente +
                          '/' +
                          props.row.idDoUsuario
                      "
                      class="icone"
                    >
                      <feather-icon icon="FileTextIcon" />
                    </a>
                    <span class="espaco">
                      <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25"
                          />
                        </template>
                        <!-- <b-dropdown-item
                          :to="{
                            name: 'user-search',
                            params: {
                              idDoUsuario: props.row.idDoUsuario,
                              idDoCliente: props.row.idDoCliente
                            }
                          }"
                        >
                          <feather-icon icon="FileTextIcon" />
                          <span class="align-middle ml-50">Detalhes</span>
                        </b-dropdown-item> -->

                        <b-dropdown-item
                          :to="{
                            name: 'user-edit',
                            params: {
                              idDoCliente: props.row.idDoCliente,
                              idDoUsuario: props.row.idDoUsuario
                            }
                          }"
                        >
                          <feather-icon
                            icon="Edit2Icon"
                            class="mr-50"
                          />
                          <span>Editar</span>
                        </b-dropdown-item>
                        <!-- <b-dropdown-item
                        v-if="props.row.statusDoUsuario != 'Ativo'"
                        @click="showModalActive(props.row)"
                      >
                        <feather-icon icon="EyeIcon" />
                        <span class="align-middle ml-50">Ativar</span>
                      </b-dropdown-item> -->
                        <!-- <b-dropdown-item
                        v-if="props.row.statusDoUsuario != 'Inativo'"
                        @click="showModalInactive(props.row)"
                      >
                        <feather-icon icon="EyeOffIcon" />
                        <span class="align-middle ml-50">Inativar</span>
                      </b-dropdown-item> -->
                        <b-dropdown-item @click="showModalRemove(props.row)">
                          <feather-icon icon="TrashIcon" />
                          <span class="align-middle ml-50">Excluir</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                  </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Mostrando 1 até
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5', '10', '30']"
                      class="mx-1"
                      @input="
                        value => props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      de {{ props.total }} registros
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      aria-controls="clientTable"
                      :total-rows="totalRows"
                      :per-page="pageLength"
                      first-number
                      last-number
                      class="mt-1 mb-0"
                      prev-class="prev-item"
                      next-class="next-item"
                      @input="
                        value => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                Nenhum registro encontrado!
              </div>
            </vue-good-table>
          </b-card-actions>
        </b-card>
        <DeleteModal
          :id="selectedUserId"
          ref="modal"
          @inactivateClient="updateModal"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  // BTable,
  // BMedia,
  // BAvatar,
  // BLink,
  // BBadge,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormSelect
} from "bootstrap-vue"
import BCardActions from "@core/components/b-card-actions/BCardActions.vue"
import vSelect from "vue-select"
import { onUnmounted } from "@vue/composition-api"
import store from "@/store"
import userStoreModule from "./userStoreModule"
import { getUserData } from "@/auth/utils"
import Ripple from "vue-ripple-directive"
import useJwt from "@/auth/jwt/useJwt"
import { VueGoodTable } from "vue-good-table"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

import DeleteModal from "./modal-delete-user.vue"

export default {
  components: {
    VueGoodTable,
    BCard,
    BCardActions,
    BRow,
    BCol,
    BFormInput,
    BButton,
    // BTable,
    // BMedia,
    // BAvatar,
    // BLink,
    // BBadge,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormSelect,

    // vSelect,
    DeleteModal
  },
  directives: { Ripple, "b-tooltip": VBTooltip },

  props: {
    selectedFilter: {
      type: String,
      required: false,
      default: ""
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user"
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      }
    })
  },
  data() {
    return {
      idDoPerfil: 0,
      selectedUserId: 0,
      ativarUsuario: false,
      inativaUsuario: false,
      excluirUsuario: false,
      editUsuario: false,
      columns: [
        {
          field: "nomeDoCliente",
          label: "Empresa",
          sortable: true
        },

        {
          field: "nomeDoUsuario",
          label: "Nome",
          sortable: true
        },
        {
          field: "cpfDoUsuario",
          label: "CPF",
          sortable: true,
          formatFn: this.formatFn
        },
        {
          field: "emailDoUsuario",
          label: "E-mail",
          sortable: true
        },
        {
          field: "descricaoDaFuncao",
          label: "Cargo",
          sortable: false
        },
        {
          field: "statusDoUsuario",
          label: "Status",
          sortable: false
        },
        {
          field: "action",
          label: "Ações"
        }
      ],
      searchQuery: "",
      pageLength: 5,
      currentPage: 1,
      dir: false,
      perPageOptions: [5, 10, 25, 50, 100]
    }
  },
  computed: {
    getUsers() {
      const idDoPerfil = Number(getUserData().idDoPerfil)

      if (idDoPerfil === 1) {
        return this.$store.getters["app-user/getUsersByCliente"](
          this.searchQuery,
          this.selectedFilter
        )
      } else {
        return this.$store.getters["app-user/getUsers"](
          this.searchQuery,
          this.selectedFilter
        )
      }
    },
    dataMeta() {
      const count = this.getUsers.length
      const auxTo = this.pageLength * (this.currentPage - 1) + this.pageLength
      return {
        from: this.pageLength * (this.currentPage - 1) + 1,
        to: auxTo > count ? count : auxTo,
        of: this.getUsers.length
      }
    },
    totalRows() {
      return this.getUsers.length
    }
  },
  mounted() {
    // this.$refs["loadingCard"].showLoading = true
    this.idDoPerfil = Number(getUserData().idDoPerfil)
    this.newFetchUser()
    this.removeColumns()
  },
  methods: {
    formatFn: function(documentoDoCliente) {
      if (documentoDoCliente.length <= 11) {
        return (documentoDoCliente = this.mascaraCpf(documentoDoCliente))
      } else {
        return (documentoDoCliente = this.mascaraCnpj(documentoDoCliente))
      }
    },
    retirarFormatacao(valor) {
      valor = valor.replace(/(\.|\/|\-)/g, "")
    },
    mascaraCpf(valor) {
      return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4")
    },
    mascaraCnpj(valor) {
      return valor.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        "\$1.\$2.\$3\/\$4\-\$5"
      )
    },

    async newFetchUser() {
      await this.$store.dispatch("app-user/newfetchUsers")
    },
    addClient() {
      this.$router.push("/cadastro-de-usuario")
    },
    addImport() {
      this.$router.push("/importar-funcionarios")
    },
    handleSelectedId(usuario) {
      this.selectedUserId = usuario.idDoUsuario
      this.$bvModal.show("modal-delete")
    },
    removeColumns() {
      if (this.idDoPerfil != 1) {
        this.columns.splice("field", 1)
      }
    },
    showModalInactive(usuario) {
      this.$bvModal
        .msgBoxConfirm("Deseja Inativar esse usuário?", {
          title: "Inativar usuário",
          size: "sm",
          okVariant: "primary",
          okTitle: "OK",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          // aqui vai o codigo para delete ou cancel
          if (value == true) {
            this.inativaUsuario = true
            this.VerificaPerfilAlterar(usuario)
          }
        })
    },
    showModalActive(usuario) {
      this.$bvModal
        .msgBoxConfirm("Deseja Ativar esse usuário?", {
          title: "Confirmar a Ativação",
          size: "sm",
          okVariant: "primary",
          okTitle: "OK",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          // aqui vai o codigo para delete ou cancel
          if (value == true) {
            this.ativarUsuario = true
            this.VerificaPerfilAlterar(usuario)
          }
        })
    },
    async VerificaPerfilAlterar(usuario) {
      try {
        const idDoCliente = Number(getUserData().idDoCliente)
        const idDoUsuario = Number(getUserData().idDoUsuario)
        const resp = await useJwt
          .consultarUsuario(idDoCliente, idDoUsuario)
          .then(response => response.data)
          .catch(response => response.data)
        if (resp.sucesso) {
          const idDoPerfil = usuario.idDoPerfil
          if (
            (idDoPerfil >= resp.dados.consultarUsuario.idDoPerfil ||
              resp.dados.consultarUsuario.idDoPerfil === 1) &&
            usuario.idDoUsuario != resp.dados.consultarUsuario.idDoUsuario
          ) {
            if (this.ativarUsuario == true) {
              this.ativarUser(usuario)
              this.ativarUsuario = false
            }
            if (this.excluirUsuario == true) {
              this.removeUser(usuario)
              this.excluirUsuario = false
            }
            if (this.inativaUsuario == true) {
              this.inactivateClient(usuario)
              this.inativaUsuario = false
            }
            if (this.editUsuario == true) {
              this.editUsuario = false
              this.$router.push({
                name: "user-edit",
                params: {
                  idDoUsuario: usuario.idDoUsuario,
                  idDoCliente: usuario.idDoCliente
                }
              })
            }
          } else {
            return this.MensagemToastVerificaPermissaoUsuario(
              "warning",
              "top-center"
            )
          }
        }
      } catch (error) {
        return this.MensagemToastErrorVerificaPermissaoUsuario(
          "danger",
          "top-center"
        )
      }
    },
    async inactivateClient(usuario) {
      this.selectedUserId = usuario.idDoUsuario
      if (this.selectedUserId != 0) {
        const id = this.selectedUserId
        const resp = await useJwt
          .inactivateUser(id)
          .then(response => response.data)
          .catch(response => {
            const catchError = Object.assign({}, response)
            return this.MensagemToastErrorInativarUsuario(
              "danger",
              "top-center",
              catchError.response.status
            )
          })
        if (resp.sucesso) {
          this.MensagemToastInativarUsuario("success", "top-center")
          setTimeout(() => {
            this.newFetchUser()
          }, 1000)
        }
      } else {
        return this.MensagemToastVerificaPermissaoInativarUsuario(
          "warning",
          "top-center"
        )
      }
    },

    async ativarUser(usuario) {
      this.selectedUserId = usuario.idDoUsuario
      if (this.selectedUserId != 0) {
        const id = this.selectedUserId
        const resp = await useJwt
          .ativarUserById(id)
          .then(response => response.data)
          .catch(response => {
            const catchError = Object.assign({}, response)
            this.MensagemToastErrorAtivarUsuario(
              "danger",
              "top-center",
              catchError.response.status
            )
          })
        if (resp.sucesso) {
          this.MensagemToastAtivarUsuario("success", "top-center")
          setTimeout(() => {
            this.newFetchUser()
          }, 1000)
        }
      } else {
        return this.MensagemToastPermissaoAtivarUsuario("warning", "top-center")
      }
    },

    showModalRemove(usuario) {
      // this.selectedUserId = id
      this.$bvModal
        .msgBoxConfirm(
          "Ao excluir o usuário você apagará permanentemente todos os dados e registros relacionados a ele do sistema. Você confirma a exclusão?",
          {
            title: "Confirmar a exclusão",
            size: "sm",
            okVariant: "primary",
            okTitle: "SIM",
            cancelTitle: "Cancelar",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true
          }
        )
        .then(value => {
          // aqui vai o codigo para delete ou cancel
          if (value == true) {
            // this.removeUser(id)
            this.excluirUsuario = true
            this.VerificaPerfilAlterar(usuario)
          }
        })
    },

    async removeUser(usuario) {
      this.selectedUserId = usuario.idDoUsuario
      if (this.selectedUserId != 0) {
        const id = this.selectedUserId
        const resp = await useJwt
          .removeUserById(id)
          .then(response => response.data)
          .catch(response => {
            const catchError = Object.assign({}, response)
            this.MensagemToastErrorRemoverUsuario(
              "danger",
              "top-center",
              catchError.response.status
            )
          })
        if (resp.sucesso) {
          this.MensagemToastRemoverUsuario("success", "top-center")
          setTimeout(() => {
            this.idDoPerfil = Number(getUserData().idDoPerfil)
            this.newFetchUser()
          }, 2000)
        }
      } else {
        return MensagemToastPermissaoRemoverUsuario("warning", "top-center")
      }
    },
    updateModal() {
      this.newFetchUser()
    },
    editUser(usuario) {
      this.editUsuario = true
      this.VerificaPerfilAlterar(usuario)
    },
    MensagemToastVerificaPermissaoUsuario(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title:
              "Desculpe, você não possui permisão para alterar esse usuário.",
            icon: "CoffeeIcon",
            variant,
            text: "Por favor, verificar se a solicitação está correta!"
          }
        },
        {
          position
        }
      )
    },
    MensagemToastErrorVerificaPermissaoUsuario(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Erro ao verificar permissão de alteração",
            icon: "ErrorIcon",
            variant,
            text: "Por favor, tente novamente mais tarde!"
          }
        },
        {
          position
        }
      )
    },
    MensagemToastErrorInativarUsuario(variant, position, mensagem) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Erro ao Inativar usuário, Por favor verifique sua conexão!",
            icon: "ErrorIcon",
            variant,
            text: mensagem
          }
        },
        {
          position
        }
      )
    },
    MensagemToastInativarUsuario(variant, position, mensagem) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Usuário Inativado com sucesso!",
            icon: "EditIcon",
            variant,
            text: mensagem
          }
        },
        {
          position
        }
      )
    },
    MensagemToastVerificaPermissaoInativarUsuario(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title:
              "Desculpe, você não tem permissão para Inativar esse usuário!",
            icon: "CoffeeIcon",
            variant,
            text: "Por favor, verificar se a solicitação está correta!"
          }
        },
        {
          position
        }
      )
    },
    MensagemToastErrorAtivarUsuario(variant, position, mensagem) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Erro ao ativar usuário, Por favor verifique sua conexão!",
            icon: "ErrorIcon",
            variant,
            text: mensagem
          }
        },
        {
          position
        }
      )
    },
    MensagemToastAtivarUsuario(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Usuário ativado com sucesso!",
            icon: "EditIcon",
            variant,
            text: mensagem
          }
        },
        {
          position
        }
      )
    },
    MensagemToastPermissaoAtivarUsuario(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Desculpe, você não tem permissão para ativar esse usuário!",
            icon: "CoffeeIcon",
            variant,
            text: "Por favor, verificar se a solicitação está correta!"
          }
        },
        {
          position
        }
      )
    },
    MensagemToastErrorRemoverUsuario(variant, position, mensagem) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Erro ao Excluir usuário, Por favor verifique sua conexão!",
            icon: "ErrorIcon",
            variant,
            text: mensagem
          }
        },
        {
          position
        }
      )
    },
    MensagemToastRemoverUsuario(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Usuário Excluído com sucesso!",
            icon: "EditIcon",
            variant
          }
        },
        {
          position
        }
      )
    },
    MensagemToastPermissaoRemoverUsuario(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title:
              "Desculpe, você não tem permissão para excluir esse usuário!",
            icon: "CoffeeIcon",
            variant
          }
        },
        {
          position
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.icone {
  color: inherit;
}

.espaco {
  padding-left: 5% !important;
  // margin-left: -40% !important;
  margin-right: -60% !important;
  display: inline;
  flex-wrap: wrap;
}
</style>

<style lang="scss">
// @import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.AjusteSearch {
  margin-right: -10px;
}
.AjusteButtonAdd {
  margin-right: -15px;
}

@media only screen and (max-width: 923px) {
  .AjusteButtonAdd {
    margin-left: -20px;
    margin-right: -30px;
  }
}

@media only screen and (max-width: 769px) {
  .AjusteButtonAdd {
    margin-left: -20px;
    margin-right: -30px;
  }
}
@media only screen and (max-width: 768px) {
  .AjusteButtonAdd {
    margin-bottom: 15px;
    margin-left: 0px;
    margin-right: -30px;
  }
}
</style>
