<template>
  <div class="client-filter">
    <UserFilter @filter="handleFilter" />
    <UserFormList :selected-filter="filter" />
  </div>
</template>

<script>
import UserFilter from '../components/user/user-filter'
import UserFormList from '../components/user/user-list'

export default {
  components: {
    UserFilter,
    UserFormList,
  },
  data() {
    return {
      filter: '',
    }
  },
  methods: {
    handleFilter(userFilter) {
      if (userFilter && userFilter.value !== null) {
        this.filter = userFilter.value
      } else {
        this.filter = ''
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
