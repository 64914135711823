/* eslint-disable comma-dangle */
/* eslint-disable semi */
/* eslint-disable arrow-parens */
import axios from '@axios'
import { baseUrl } from '@/constants'
import { removeAcento, removeAcentoEmail } from '@/auth/utils'

export default {
  namespaced: true,
  state: {
    users: [],
  },
  getters: {
    getUsersByCliente: (state) => (searchQuery, filter) =>
      searchQuery !== '' || filter !== ''
        ? state.users.filter((user) => {
          searchQuery = searchQuery.toLowerCase()
          console.log('Usuarios: ', user)
            return (
              (removeAcento(user.nomeDoUsuario).includes(searchQuery) ||
                user.nomeDoUsuario.toLowerCase().includes(searchQuery) ||
                user.nomeDoCliente.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchQuery) ||
                user.nomeDoCliente.toLowerCase().includes(searchQuery) ||
                user.cpfDoUsuario.includes(searchQuery) ||
                user.descricaoDaFuncao.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchQuery) ||
                user.descricaoDaFuncao.toLowerCase().includes(searchQuery) ||
                user.statusDoUsuario.toLowerCase().includes(searchQuery) ||
                user.statusDoUsuario.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchQuery) ||
               user.emailDoUsuario.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchQuery)) &&
              (user.statusDoUsuario.toLowerCase() === filter.toLowerCase() ||
                filter === '')
            )
          })
        : state.users,
        getUsers: (state) => (searchQuery, filter) =>
      searchQuery !== '' || filter !== ''
        ? state.users.filter((user) => {
          searchQuery = searchQuery.toLowerCase()
          console.log('Usuarios: ', user)
            return (
              (removeAcento(user.nomeDoUsuario).includes(searchQuery) ||
                user.nomeDoUsuario.toLowerCase().includes(searchQuery) ||
                user.cpfDoUsuario.includes(searchQuery) ||
                user.descricaoDaFuncao.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchQuery) ||
                user.descricaoDaFuncao.toLowerCase().includes(searchQuery) ||
                user.statusDoUsuario.toLowerCase().includes(searchQuery) ||
                user.statusDoUsuario.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchQuery) ||
               user.emailDoUsuario.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchQuery)) &&
              (user.statusDoUsuario.toLowerCase() === filter.toLowerCase() ||
                filter === '')
            )
          })
        : state.users,
  },
  mutations: {
    SAVE_USER(state, users) {
      state.users = users
    },
  },
  actions: {
    newfetchUsers({ commit }) {
      return new Promise((reject) => {
        axios
          .get(
            `${baseUrl}/api/usuario/listar-usuario`
          )
          .then((response) => {
            commit('SAVE_USER', response.data.dados.usuarios)
          })
          .catch((error) => reject(error))
      })
    },
  },
}
