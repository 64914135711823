<template>
  <b-modal
    id="modal-delete"
    title="Inativar usuário"
    centered
    cancel-title="Cancelar"
    cancel-variant="outline-secondary"
    @ok="inactivateClient()"
  >
    <b-card-text>
      Deseja Inativar esse usuário?
    </b-card-text>
  </b-modal>
</template>

<script>
import { BModal, VBModal, BCardText } from "bootstrap-vue"
import { onUnmounted } from "@vue/composition-api"
import store from "@/store"
import userStoreModule from "./userStoreModule"
import { getUserData } from "@/auth/utils"

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

import useJwt from "@/auth/jwt/useJwt"

export default {
  components: {
    BCardText,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent
  },
  directives: {
    "b-modal": VBModal
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user"

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      }
    })
  },
  methods: {
    async inactivateClient() {
      const id = this.id
      const idUsuario = Number(getUserData().idDoUsuario)
      if (id != idUsuario) {
        const resp = await useJwt
          .inactivateUser(id)
          .then(response => response.data)
          .catch(response => {
            const catchError = Object.assign({}, response)
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  "Erro ao Inativar usuário, Por favor verifique sua conexão!",
                icon: "ErrorIcon",
                variant: "danger",
                text: catchError.response.status
              }
            })
          })
        if (resp.sucesso) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Usuário Inativado!",
              icon: "EditIcon",
              variant: "success"
            }
          })

          this.$emit("inactivateClient")
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                "Erro ao Inativar usuário, Por favor verifique sua conexão!",
              icon: "ErrorIcon",
              variant: "danger"
            }
          })
        }
      }
    }
  }
}
</script>

<style></style>
