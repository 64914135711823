<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filtros
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Status do usuário</label>
          <v-select
            :value="statusOptions.value"
            :options="statusOptions"
            class="w-100"
            @input="val => $emit('filter', val)"
          >
            <span slot="no-options">
              Opção não encontrada
            </span>
          </v-select>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { BCard, BCardHeader, BCardBody, BRow, BCol } from "bootstrap-vue"
import vSelect from "vue-select"
import { methods } from "vue-echarts"

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect
  },
  data() {
    return {
      statusOptions: [
        { label: "Ativo", value: "Ativo" },
        { label: "Inativo", value: "Inativo" },
        { label: "Férias", value: "Férias" },
        { label: "Afastado", value: "Afastado" }
      ],

      statusOptionss: []
    }
  },
  methods: {}
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
